const PCS = {
	addresses: {
		"factory": "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
		"masterchef": "0x73feaa1eE314F8c655E354234017bE2193C9E24E",
		"router": "0x10ED43C718714eb63d5aA57B78B54704E256024E",
		"vault": "0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC",
		"smartChefFactory": "0x927158Be21Fe3D4da7E96931bb27Fd5059A8CbC2",
		
		"CAKE": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
	},
};

export default PCS;
